#services-wrapper {
  z-index: 500;
  margin-top: 5vh;
  height: calc(100% - 5vh);
}

.a_service > h4 {
  
  margin-top: 30px;
  text-align: center;
  font-weight: bold;
}

.a_service {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 500ms, transform 500ms;
}

.a_service > p {
  text-align: center !important;
  margin-top: 20px;
  font-weight: 400;
  font: 16px var(--acumin-pro);
}

.services_section {
  height: unset !important;
  min-height: 100vh !important;
  background-size: cover;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 90%, rgba(255,255,255,0) 100%);
  z-index: 1;
}

.services_overlay {
  height: 100%;
  width: 100vw;
  top: 0px;
  left: 0px;
}

.service_icon_container {
  margin: auto;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: white;
  width: 90px;
  height: 72px;
  border-radius: 11px;
  box-shadow: 0px 3px 6px #00000042;
}

#four-services {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: space-between;
}

p.light_grey_text {
  padding-right: 30px;
}

#service-section-notice {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* MEDIA QUERY FOR LARGE TABLETS AND SMALL DESKTOP */
@media only screen and (max-width:992px) {
  
  #services-wrapper > h2 {
    margin-bottom: 50px;
  }

  .services_section {
    padding-top: 100px;
    height: unset;
    background-size: cover;
  }

  #four-services {
    padding-top: 20px;
    justify-content: center !important;
    flex-wrap: wrap;
  }

  .services_overlay {
    height: 100%;
  }

  .a_service {
    margin-top: 30px;
  }

  div [title=service-second-row] {
    margin-top: 60px
  }

  #service-section-notice {
    position: unset;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width:768px){

  #four-services {
    padding-top: 0px;
  }

  #services-wrapper > h2 {
    margin-bottom: 20px;
  }

  #services-wrapper {
    margin-top: 0;
  }

  .services_section {
    padding-top: 100px;
    height: unset;
  }
 }

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px){

  #four-services {
    margin-top: 0 !important;
  }

  #service-section-notice {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .a_service, div [title=service-second-row] {
    margin-top: 50px;
  }

  p.light_grey_text {
    font-size: 13px !important;
    text-align: left !important;
    padding: 0 15px;
  }
}

